<template>
	<Portal to="modal-content">
		<ValidationObserver ref="observer" tag="form" v-slot="{ invalid }" @submit.prevent="handleSubmit">
			<section v-if="isInitChallenge">
				<p>
					<small>
						Protecting your identity is our top priority. Please select a destination to receive your
						security code.
					</small>
				</p>

				<StsVRadioGroup
					v-model="selectedChallengeDestinationId"
					name="Please select an option"
					:options="options"
					validationRules="required"
				/>

				<StsButton
					fullwidth
					lowercase
					small
					style="margin-bottom: 1rem"
					title="Submit"
					@click="loadChallenge()"
				/>
			</section>

			<section v-else-if="hasChannelDestinations && selectedChannelDestination && !isFailure">
				<p>
					<small>
						Protecting your identity is our top priority. Please confirm your identity by entering the
						authorization code sent to
						{{ selectedChannelDestination.displayDestination }}
					</small>
				</p>

				<StsVMultiStepInput
					v-model="action.token"
					:steps="tokenLength"
					label="Authentication Code"
					disableAutocomplete
					type="tel"
					validationRules="required|digits:6"
					hideLabel
					style="margin: 1rem 15%"
				/>

				<StsButton
					fullwidth
					lowercase
					small
					style="margin-bottom: 1rem"
					title="Submit"
					:disabled="invalid"
					@click="handleClick()"
				/>
			</section>

			<section v-else-if="hasChannelDestinations && selectedChannelDestination && isFailure">
				<p>
					<small class="error">The information provided did not match what we have on record</small>
				</p>

				<StsButton
					fullwidth
					lowercase
					small
					style="margin-bottom: 1rem"
					title="Restart"
					@click="restartClick()"
				/>
			</section>

			<section v-else>
				<p>
					<small>We're unable to complete this request.</small>
				</p>
			</section>
		</ValidationObserver>
	</Portal>
</template>





<script>
import { Portal } from 'portal-vue';
import { mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { createToastInterface } from 'vue-toastification';

import { ChallengeActionResult } from '@/constants';
import { StsButton } from '@/components/SwitchThink';
import { StsVRadioGroup, StsVMultiStepInput } from '@/components/SwitchThink/form';
import showSpinner from '@/mixins/showSpinner';
import { toastConfig } from '@/utils';

const toast = createToastInterface(toastConfig);

export default {
	name: 'ChallengeModal',
	mixins: [showSpinner],
	components: {
		Portal,
		StsButton,
		StsVRadioGroup,
		StsVMultiStepInput,
		ValidationObserver
	},
	data() {
		return {
			action: {
				token: '',
				challengeId: 0
			},
			tokenLength: 6,
			showFailure: false,
			challengeDestinations: [],
			options: [],
			selectedChallengeDestinationId: 0,
			isInitChallenge: true,
			isFailure: false
		};
	},
	async created() {
		this.challengeDestinations = await this.getChallengeDestinations();
		this.options = this.challengeDestinations.map(destination => ({
			label: destination.displayDestination,
			value: destination.destinationId
		}));

		if (this.challengeDestinations != null && this.challengeDestinations.length === 1) {
			this.selectedChallengeDestinationId = this.challengeDestinations[0].destinationId;
			await this.loadChallenge();
		}

		return this.triggerModal({
			isOpen: true,
			title: 'Authenticate Your Account',
			smallTitle: true,
			preventClose: true
		});
	},
	computed: {
		hasChannelDestinations() {
			return this.challengeDestinations != null && this.challengeDestinations.length > 0;
		},
		selectedChannelDestination() {
			return this.hasChannelDestinations
				? this.challengeDestinations.find(
						destination => destination.destinationId === this.selectedChallengeDestinationId
				  )
				: null;
		}
	},
	beforeDestroy() {
		return this.triggerModal({ isOpen: false });
	},
	methods: {
		...mapActions({
			triggerModal: 'modal/triggerModal',
			createChallenge: 'challenge/createChallenge',
			createChallengeAction: 'challenge/createChallengeAction',
			getChallengeDestinations: 'challenge/getChallengeDestinations'
		}),
		async loadChallenge() {
			try {
				this.showSpinner({ submitting: true });

				this.action.challengeId = await this.createChallenge(this.selectedChannelDestination);

				if (this.action.challengeId) {
					this.isInitChallenge = false;
				}

				return this.showSpinner({ submitting: false });
			} catch (error) {
				this.showSpinner({ submitting: false });

				return toast.error(error);
			}
		},
		async handleClick() {
			try {
				this.showSpinner({ submitting: true });

				const result = await this.createChallengeAction(this.action);

				this.showSpinner({ submitting: false });

				return result === ChallengeActionResult.VERIFIED ? this.$emit('close') : (this.isFailure = true);
			} catch (error) {
				this.showSpinner({ submitting: false });

				return toast.error(error);
			}
		},
		restartClick() {
			this.isInitChallenge = true;
			this.isFailure = false;
			this.action.token = '';
		}
	}
};
</script>





<style lang="scss" scoped>
.joint-owners {
	display: grid;
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	grid-template-columns: repeat(auto-fit, minmax(6.3125rem, 1fr));

	h2 {
		margin-top: 1rem;
		font-size: 1.75rem;
		color: var(--primary);
		font-weight: 400;
		transition: color 0.5s;
	}

	p {
		color: var(--primary);
		font-weight: 300;
		transition: color 0.5s;
	}

	button.selected p,
	button.selected h2 {
		color: var(--success);
	}
}
</style>